<template>
  <van-nav-bar
    :title="title"
    :left-text="''"
    :left-arrow="leftText !== ''"
    @click-left="onClickLeft"
    @click-right="onClickRight"
  />
</template>

<script>
import {h5BackToApp} from "../utils/h5AppUtils";
export default {
  name: "NavBar",
  computed: {
    leftText() {
      return this.$route.path == "/" ? "" : "show";
    },
    title(){
      return this.$route.meta.title?this.$route.meta.title:'H5页面'
    }
  },
  methods: {
    onClickLeft() {
      console.log("route", this.$route)
      if (this.$route.query && this.$route.query.type) {
        // 商品确认订单跳到其他页面点击左上角的返回时
        //之后会是一个复杂的逻辑
      } else {
        if (this.$route.name === "NPS"){
          h5BackToApp();
        }else{
          this.$router.go(-1);
        }

      }
    },
    onClickRight() {},
  },
};
</script>

<style scoped lang="stylus"></style>
