const ua = getUa();
export function h5BackToApp() {
    console.log("ua", ua);
    if(typeof historyBack=='function'){
        // eslint-disable-next-line no-undef
        historyBack();
    }else if (ua!='ios'&&typeof window.App!= undefined) {
        window.App.historyBack();
    }else if (typeof window.webkit.messageHandlers.historyBack!= undefined) {
        window.webkit.messageHandlers.historyBack.postMessage({});
    }else {
        history.back(-1);
    }
}

function getUa(){
    let agent = navigator.userAgent.toLowerCase();
    if (agent.includes("yzg_android")){
        return "android"
    }else{
        return "ios"
    }
}
