import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.less';
import './style/mod.less';
import 'lib-flexible'
import globaLoading from './components/globaLoading'
Vue.config.productionTip = false

import importVant from "./utils/importVant";
importVant(Vue);
Vue.use(globaLoading);

Vue.use(require('vue-wechat-title'));

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
