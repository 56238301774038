import store from '@/store'
const loading = {
    show(tip = "加载中...") {
        // 显示方法
        store.commit('account/setLoading', { loading: true, tip })
    },
    hide() {
        store.commit('account/setLoading', { loading: false, tip: '' })
    }
};

export default {
    install(Vue) {
        if (!Vue.$loading) {
            Vue.$loading = loading;
        }
        Vue.mixin({
            beforeCreate() {
                this.$loading = Vue.$loading;
            }
        });
    }
};