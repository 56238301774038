// 判断是否是小程序环境
export function IsMiniProgramEnv() {
  // 通过navigator.userAgent 判断
  return navigator.userAgent && navigator.userAgent.indexOf("miniProgram") > -1;
}

export function isHarmonyOS() {
  console.log("isHarmonyOS", JSON.stringify(navigator.userAgent));
  console.log("isHarmonyOS", JSON.stringify(navigator.vendor));
  console.log("isHarmonyOS", JSON.stringify(window.opera));
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log("isHarmonyOS", userAgent.indexOf("OpenHarmony"));
  return userAgent.indexOf("OpenHarmony") > -1;
}
