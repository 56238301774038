import axios from 'axios'
import { Toast } from 'vant';
import store from '@/store'
let requestNum = 0

// create an axios instance
const service = axios.create({
    //baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        //'Authorization': 'BearereyJpdiI6IkZsWTRQdWxORTV6RzdXaGRvcDVhR3c9PSIsInZhbHVlIjoicnVjV1FPV1JFV2gxaHBDSktBeVc2bzAwYVNkRnpKVjZBREhWbHlXNmtpem05NVhKNjc0QkdpVzBId1dKZ3daNXFzSEdGN25sb1wvKzFsYmtwMUlPcVZtN0diMkh5cUpiUHhPMHNtNUg5SkxQS2luakdrVU1cL3FmQnVFYTdtN1p6UnNyTnRZNGg2MFkybTY0eEhyb091U2c9PSIsIm1hYyI6IjRiMmNlMTgxYzQ5ODg2MDlkYmU2NTkxZTE4Mjk4ZDYyNTEwNTlmYWUzMDIyMGU0ZGVhYzY5NTEwYzQzYWVhNWUifQ=='
    },
})

// 请求前
service.interceptors.request.use(
    config => {
        if (store.getters['account/token']) {
            config.headers['Authorization'] = store.getters['account/token']
        }
        if (!config.data.innid) {
            config.data.innid = store.getters['account/user'].innid
        }
        if (config.method.toUpperCase() === 'GET') {
            config.params = config.data;
            config.data = undefined;
        } else if (config.method.toUpperCase() === 'POST') {
            config.params = undefined;
        }
        console.log("config", config)
        if (config.loading != false) {
            requestNum += 1
            store.commit('account/setLoading', { loading: true, tip: '加载中' })
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 请求后
service.interceptors.response.use(
    response => {
        const res = response.data
        requestNum -= 1
        if (requestNum <= 0) {
            store.commit('account/setLoading', { loading: false, tip: '' })
        }
        if (res.ret_code !== 0) {
            Toast.fail(res.ret_msg);
            return Promise.reject(res)
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service