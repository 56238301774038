export default {
    namespaced: true,
    state: {
        user: {
            innid: undefined
        },
        token: null,
        loading: false,
        tip: '加载中'
    },
    //读取
    getters: {
        // token读取
        token: state => {
            if (!state.token) {
                try {
                    const token = sessionStorage.getItem("token")
                    state.token = token ? token : ""
                } catch (e) {
                    console.error(e)
                }
            }
            return state.token
        },
        // loading状态读取
        loading: state => {
            return {
                loading: state.loading,
                tip: state.tip
            }
        },

        // 用户信息读取
        user: state => {
            if (!state.user) {
                try {
                    const user = sessionStorage.getItem("userInfo")
                    state.user = JSON.parse(user)
                } catch (e) {
                    console.error(e)
                }
            }
            return state.user
        }
    },
    //写入
    mutations: {
        //写入用户信息
        setUser(state, user) {
            state.user = user
            sessionStorage.setItem("userInfo", JSON.stringify(user))
        },
        //写入token
        setToken(state, token) {
            state.token = token
            sessionStorage.setItem("token", token)
        },
        //写入loading状态
        setLoading(state, { loading, tip }) {
            state.loading = loading
            state.tip = tip
        },

    }
}