<template>
  <div id="app">
    <div class="mask_loading" v-show="loading.loading">
      <van-loading size="45" text-color="#FFFFFF" text-size="15" vertical>{{
        loading.tip
      }}</van-loading>
    </div>
    <navBar-vue v-if="!isWeixinApplet && !isHarmonyOs" class="h5_nav"></navBar-vue>
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>
<script>
import NavBarVue from "./components/NavBar.vue";
import { mapGetters } from "vuex";
import {isHarmonyOS, IsMiniProgramEnv} from "./utils/h5AppletUtils";
export default {
  name: "App",
  components: {
    NavBarVue
  },
  computed: {
    ...mapGetters("account", ["loading"]),
  },
  data() {
    return {
      isWeixinApplet: false,
      isHarmonyOs: false
    };
  },
  mounted() {
    this.judgeApplet();
  },
  methods:{
    judgeApplet(){
      this.isWeixinApplet = IsMiniProgramEnv();
      this.isHarmonyOs = isHarmonyOS();
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #f5f5f5;
  margin: 0;
  padding: 0;
  z-index: 9;
}
.mask_loading {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.h5_nav {
  position: fixed !important;
  top: 0;
  width: 100%;
}
</style>
