import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getUserToken } from './../services/user'
Vue.use(VueRouter)

const routes = [
    {
        path: "/AIhardware",
        name: "AIhardware",
        component: () =>
            import ("../view/AIhardware/AIhardwareIndex.vue"),
        meta: {
            title: '智能硬件'
        },
        children: [{
                path: "/AIhardware",
                name: "AIIntroduce",
                component: () =>
                    import ("../view/AIhardware/AIIntroduce.vue"),
                meta: {
                    title: '智能硬件'
                },
            },
            {
                path: "/AIhardware/AIInquiry",
                name: "AIInquiry",
                component: () =>
                    import ("../view/AIhardware/AIInquiry.vue"),
                meta: {
                    title: '智能硬件'
                },
            }
        ]
    },
    {
        path: "/NPS",
        name: "NPS",
        component: () =>
            import ("../view/NPS/NPSIndex.vue"),
        meta: {
            title: '产品满意度调研'
        },
    },
    {
        path: "/private",
        name: "private",
        component: () =>
            import ("../view/Private/Private.vue"),
        meta: {
            title: '隐私政策'
        },
    },
    {
        path: "/agreement",
        name: "agreement",
        component: () =>
            import ("../view/Agreement/Agreement.vue"),
        meta: {
            title: '服务协议'
        },
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

//const blackList = ['/list'] // 需要登录的页面

router.beforeEach((to, from, next) => {
    if (to.query.cookiekey && to.query.innid) {
        let cookie_key=to.query.cookiekey
        let innid = to.query.innid
        console.log(cookie_key,innid)
        // 当为ios时  cookie_key为数组
        if(cookie_key instanceof Array){
            cookie_key=to.query.cookiekey[0]
        }
        if(innid instanceof Array){
            innid=to.query.innid[0]
        }
        getUserToken({ cookie_key }).then(res => {
            store.commit('account/setToken', res.ret_data.access_token);
            store.commit('account/setUser', { innid });
            next()
        })
    } else {
        next()
    }
    // if (sessionStorage.token&&sessionStorage.) {
    //     next()
    // } else {
    //     next()
    //     if (blackList.indexOf(to.path) !== -1) {
    //         next('/login')
    //     } else {
    //         next()
    //     }
    // }
    next()
})
export default router
