import request from '@/utils/request'
const BASE_URL = process.env.VUE_APP_API_BASE_URL


/**
 * H5 换取token
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getUserToken(data) {
    return request({
        url: `${BASE_URL}api/v1/app/get_user_token`,
        method: 'post',
        data
    })
}



export default {
    getUserToken
}