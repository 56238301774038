import {
    NoticeBar,
    NavBar,
    TabbarItem,
    Button,
    Loading,
    Dialog,
    Swipe,
    SwipeItem,
    Icon,
    Image as VanImage,
    Calendar,
    Search,
    Popup,
    Cell,
    CellGroup,
    Tag,
    Grid,
    GridItem,
    Overlay,
    DropdownMenu,
    DropdownItem,
    Empty,
    Field,
    Form,
    TreeSelect,
    Row,
    Col,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Switch,
    Area,
    Divider,
    Toast,
    Stepper,
    SubmitBar,
    CountDown,
    List,
    PullRefresh,
    Picker,
    Checkbox,
    CheckboxGroup
} from 'vant';
const importComponents = {
    NavBar,
    TabbarItem,
    Button,
    Loading,
    Dialog,
    Swipe,
    SwipeItem,
    Icon,
    VanImage,
    Calendar,
    Search,
    Popup,
    Cell,
    CellGroup,
    Tag,
    Grid,
    GridItem,
    Overlay,
    DropdownMenu,
    DropdownItem,
    Empty,
    Field,
    Form,
    TreeSelect,
    Row,
    Col,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Switch,
    Area,
    Divider,
    Toast,
    Stepper,
    SubmitBar,
    CountDown,
    NoticeBar,
    List,
    PullRefresh,
    Picker,
    Checkbox,
    CheckboxGroup
}


export default Vue => {
    Object.keys(importComponents).forEach(key => {
        Vue.use(importComponents[key]);
    });
};
